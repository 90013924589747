<template>
  <div class="donate">
    <div class="flex-column align-items-center">
      <h5 class="booking-payment-header">BOOKING PAYMENT</h5>

      <div class="h-auto">

        <div class="row h-100 align-items-center pt-4 pb-4">
          <div class="col-12 col-lg-8 col-md-10 col-sm-12 col-xs-12 offset-md-1 offset-lg-2">
            <div class="card shadow sm">
              <div class="card-body">
                <form id="booking-payment-form" class="flex-column align-items-center" >
                  <div v-if="!initialLoad">
                    <div class="row">
                      <div class="col-12 col-xs-12 col-sm-12 col-md-12 col-lg-12 m-0 p-0 text-center">
                        <h5>Invoice status is: {{ invoice.status }}</h5>
                      </div>
                    </div>
                  </div>
                  <div v-if="!initialLoad">
                    <div class="row">

                      <div v-if="!initialLoad && !isInvoice" class="row m-0 mt-2 mb-2 p-0 d-flex justify-content-center">
                        <div class="col-12 col-xs-12 col-sm-12 col-md-6 col-lg-6 px-5">
                          <input id="card-holder-name" placeholder="Card holder name..." type="text" v-model="cardHolderName" class="form-control mb-2">

                          <div id="payment-card" class="form-control">
                            <!-- A Stripe Element will be inserted here. -->
                          </div>
                          <div id="error-message">
                            <!-- Display error message to your customers here -->
                          </div>
                        </div>
                      </div>

                      <div v-if="!initialLoad && !isInvoice" class="row m-0 mt-2 mb-2 p-0  d-flex justify-content-center">
                        <div class="col-12 col-xs-12 col-sm-6 col-md-6 col-lg-6 px-5">
                          <button type="submit" :disabled="processing" @click.prevent="payForBooking()" class="btn btn-outline-primary">
                            {{ processing ? "Please wait" : "Submit" }}
                          </button>
                        </div>
                      </div>

                      <div class="col-12 col-xs-12 col-sm-12 col-md-12 col-lg-12 m-0 p-5 text-start">

                        <div class="row booking-info p-2">
                          <div class="col-3 col-xs-12 col-sm-12 col-md-3 col-lg-3 m-0 p-0">
                            <strong>Price</strong>
                          </div>
                          <div class="col-3 col-xs-12 col-sm-12 col-md-3 col-lg-3 m-0 p-0">
                            <strong>Provision</strong>
                          </div>
                          <div class="col-3 col-xs-12 col-sm-12 col-md-3 col-lg-3 m-0 p-0">
                            <strong>Guests</strong>
                          </div>
                          <div class="col-3 col-xs-12 col-sm-12 col-md-3 col-lg-3 m-0 p-0">
                            <strong>Pets</strong>
                          </div>
                        </div>
                        <div class="row booking-info p-2">
                          <div class="col-3 col-xs-12 col-sm-12 col-md-3 col-lg-3 m-0 p-0">
                            £{{ convertNumberToCommas(booking.price) }}
                          </div>
                          <div class="col-3 col-xs-12 col-sm-12 col-md-3 col-lg-3 m-0 p-0">
                            £{{ convertNumberToCommas(booking.provision) }}
                          </div>
                          <div class="col-3 col-xs-12 col-sm-12 col-md-3 col-lg-3 m-0 p-0">
                            {{ booking.guests }}
                          </div>
                          <div class="col-3 col-xs-12 col-sm-12 col-md-3 col-lg-3 m-0 p-0">
                            {{ booking.pets }}
                          </div>
                        </div>

                        <div class="row booking-info mt-2 p-2">
                          <div class="col-3 col-xs-12 col-sm-12 col-md-3 col-lg-3 m-0 p-0">
                            <strong>Start Date</strong>
                          </div>
                          <div class="col-3 col-xs-12 col-sm-12 col-md-3 col-lg-3 m-0 p-0">
                            <strong>End Date</strong>
                          </div>
                          <div class="col-3 col-xs-12 col-sm-12 col-md-3 col-lg-3 m-0 p-0">
                            <strong>Check-In</strong>
                          </div>
                          <div class="col-3 col-xs-12 col-sm-12 col-md-3 col-lg-3 m-0 p-0">
                            <strong>Checkout</strong>
                          </div>
                        </div>
                        <div class="row booking-info p-2">
                          <div class="col-3 col-xs-12 col-sm-12 col-md-3 col-lg-3 m-0 p-0">
                            {{ booking.booking_start_date ? moment.utc(booking.booking_start_date).format('DD-MM-YYYY HH:mm') : 'N/A' }}
                          </div>
                          <div class="col-3 col-xs-12 col-sm-12 col-md-3 col-lg-3 m-0 p-0">
                            {{ booking.booking_end_date ? moment.utc(booking.booking_end_date).format('DD-MM-YYYY HH:mm') : 'N/A' }}
                          </div>
                          <div class="col-3 col-xs-12 col-sm-12 col-md-3 col-lg-3 m-0 p-0">
                            {{ booking.check_in ? booking.check_in : 'N/A' }}
                          </div>
                          <div class="col-3 col-xs-12 col-sm-12 col-md-3 col-lg-3 m-0 p-0">
                            {{ booking.check_out ? booking.check_out : 'N/A' }}
                          </div>
                        </div>

                      </div>

                      <div class="col-12 col-xs-12 col-sm-12 col-md-12 col-lg-12 m-0 p-0 ps-5 pe-5 text-center">
                        <div class="row text-center">
                          <div class="text-center w-100">
                            {{ booking.property.address.full_address }}
                          </div>
                        </div>
                        <div class="row property-thumbnails">
                          <div class="property-thumbnails-box text-center">
                            <div :id="'carouselCaptionsPayment'" class="carousel slide" data-bs-ride="carousel">

                              <div class="carousel-inner">
                                <div v-if="booking.property.documents_property_images.length === 0">
                                  <img :src="require(`@/assets/no-image-found.png`)"
                                       class="d-block w-100 img-fluid thumbnails-height"
                                       alt="..."
                                  >
                                </div>
                                <div v-for="(doc, num) in booking.property.documents_property_images" :key="num" :class="num === 0 ? 'active carousel-item' : 'carousel-item'">
                                  <img :src="`${$s3bucket}${doc.file_path}`" @error="setDefaultImg" class="d-block w-100 img-fluid thumbnails-img" alt="...">
                                </div>
                              </div>

                              <button class="carousel-control-prev" type="button" :data-bs-target="'#carouselCaptionsPayment'" data-bs-slide="prev" v-on:click.stop="preventParentClick()">
                                <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                                <span class="visually-hidden">Previous</span>
                              </button>
                              <button class="carousel-control-next" type="button" :data-bs-target="'#carouselCaptionsPayment'" data-bs-slide="next" v-on:click.stop="preventParentClick()">
                                <span class="carousel-control-next-icon" aria-hidden="true"></span>
                                <span class="visually-hidden">Next</span>
                              </button>

                            </div>
                          </div>
                        </div>
                      </div>

                    </div>

                  </div>

                  <div v-if="showErrors && validationErrors" class="global-error mt-2">
                    <ValidationErrors :errors="validationErrors"/>
                  </div>

                  <div v-show="showThankYouMessage" class="thank-you-msg-container pt-2">
                    <p class="fw-bold">{{ this.thankYouMessage }}</p>
                  </div>

                  <div style="margin-top: 150px;" id="thankYou"></div>

                </form>
              </div>
            </div>
          </div>
        </div>

      </div>

    </div>
  </div>
</template>

<script>
import axios from "axios"
import ValidationErrors from '../ValidationErrors'

export default {
  name: 'BookingPayment',
  props: ['token'],
  components: {
    'ValidationErrors': ValidationErrors
  },
  data () {
    return {
      stripeApiToken: '',
      stripe: '',
      elements: '',
      card: '',
      intentToken: '',
      cardHolderName: '',
      paymentStatus: 0,
      paymentError: '',
      paymentMethods: [],
      paymentMethodsLoadStatus: 0,
      paymentMethodSelected: {},
      booking: '',
      invoice: '',
      isInvoice: false,
      paymentPrice: "",
      paymentPlan: process.env.VUE_APP_STRIPE_BOOKING_PAYMENT_PRICE,
      processingIntent: false,
      processing: false,
      initialLoad: true,
      showThankYouMessage: false,
      thankYouMessage: '',
      validationErrors: [],
      showErrors: false
    }
  },
  mounted: async function () {
    // Get the booking and invoice for this specific payment
    await this.getPaymentData(this.token);

    if (this.initialLoad === true || this.isInvoice === true) {
      return;
    }

    // Get stripe API key
    await this.getStripeApiKey();

    // Load User Intent for Stripe Payment System
    this.loadUserIntent();
    // Load User Payment Methods from Stripe (if exists)
    // this.loadPaymentMethods();

    this.stripe = Stripe( this.stripeApiToken );

    this.elements = this.stripe.elements();
    this.card = this.elements.create('card', {
      hidePostalCode: true
    });

    this.card.mount('#payment-card');
  },
  beforeDestroy() {
  },
  methods: {
    preventParentClick() {
      // Do nothing, its just used to prevent parent function trigger (setActivePropertyDetailsThumbnail)
    },
    getImage(file_name) {
      try {
        return require(`@/assets/${file_name}`);
      } catch (e) {
        return false
      }
    },
    setDefaultImg(e) {
      e.target.src = require(`@/assets/no-image-found.png`);
    },
    convertNumberToCommas(num) {
      if (num && num !== '' && num !== null) {
        return num.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",");
      } else {
        return 0;
      }
    },
    // convertNumberToCommas Fixed 2 (for precise price calculations)
    convertNumberToCommas2Fixed(num) {
      if (num && num !== '' && num !== null) {
        let no = num.toFixed(2);
        return no.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",");
      } else {
        return 0;
      }
    },
    async getStripeApiKey() {
      this.processing = true;
      await axios.get('api/stripe/get-api-key').then(({data})=>{
        this.stripeApiToken = data;
        this.processing = false
      }).catch(({response})=>{
        alert(response.data.message)
        this.processing = false
      }).finally(()=> {
        this.processing = false
      })
    },
    loadUserIntent() {
      this.loadIntent();
    },
    async loadIntent() {
      this.processingIntent = true;
      await axios.get('api/user/setup-intent').then(({data})=>{
        this.intentToken = data;
        this.processingIntent = false
      }).catch(({response})=>{
        alert(response.data.message)
        this.processingIntent = false
      }).finally(()=> {
        this.processingIntent = false
      })
    },
    async getPaymentData(token = null) {
      this.processing = true;

      if (!token || token === null) {
        this.processing = false;
        return;
      }

      let formData = new FormData();
      formData.append('guest_id', this.$store.state.auth.user.id);
      formData.append('token', token);

      await axios.post('api/booking-payment', formData).then(({data})=>{
        if (data.type && data.type === 'invoice') {
          this.isInvoice = true;
        }

        this.invoice = data.invoice;
        this.booking = data.booking;
        this.paymentPrice = data.booking.price;

        this.validationErrors = '';
        this.showErrors = false;
        this.initialLoad = false;
        this.processing = false;
      }).catch((error)=> {
        if (error && error.response) {
          this.validationErrors = {'error': [error.response.data[0]]};
          this.showErrors = true;
        } else if (error) {
          this.validationErrors = {'error': [error]};
          this.showErrors = true;
        }
        this.processing = false;
      }).finally(()=> {
        this.processing = false;
      })
    },
    async payForBooking() {
      this.processing = true;
      this.paymentStatus = 1;

      this.stripe.confirmCardSetup(
          this.intentToken.client_secret, {
            payment_method: {
              card: this.card,
              billing_details: {
                name: this.cardHolderName
              }
            }
          }
      ).then(async function (result) {
        if (result.error) {
          this.paymentStatus = 3;
          this.validationErrors = [];
          // Assign error so it can be handled by ValidationErrors component
          this.validationErrors[0] = {0: result.error.message};
          this.showErrors = true;
          // console.log(result);
          this.processing = false;
        } else {
          this.showErrors = false;
          this.validationErrors = [];
          this.paymentMethodSelected = result.setupIntent.payment_method;
          // console.log(result);
          // console.log('start NOT paymentMethodSelected');
          // console.log(result.setupIntent.payment_method);
          // console.log(this.paymentMethodSelected);
          // console.log('end NOT paymentMethodSelected');
          // this.savePaymentMethod(result.setupIntent.payment_method);
          let payment = await this.chargeBookingPayment(result.setupIntent.payment_method);

          if (payment === true) {
            // Here I will add updateSubscription(method) to update subscription and pass payment_method to it
            // this.updateSubscription();
            this.paymentStatus = 2;
            this.card.clear();
            this.cardHolderName = '';
            // console.log(result);

            this.thankYouMessage = "Thanks! We've got this!"
            this.showThankYouMessage = true;
            let thankYou = document.getElementById('thankYou');
            if (thankYou) {
              thankYou.scrollIntoView({behavior: 'smooth', block: 'center'});
            }

            // Reload booking/invoice data
            await this.getPaymentData(this.token);

            this.processing = false;

            const timeout = setTimeout(() => {
              this.showThankYouMessage = false;
              this.thankYouMessage = null;
              clearTimeout(timeout);
            }, 5000);
          }
        }
      }.bind(this)).finally(() => {
        // Load user intent again
        this.loadUserIntent();
        this.processing = false;
      });
    },
    async chargeBookingPayment(method) {
      return await axios.post('/api/user/charge-booking-payment', {
        payment_method: method,
        plan: this.paymentPlan,
        price: this.paymentPrice,
        token: this.token,
        user_id: this.$store.state.auth.user.id
      }).then(function(response) {
        this.validationErrors = null;
        this.showErrors = false;
        return true;
        // console.log(response);
      }.bind(this)).catch(({response})=> {
        if (response && response.data && response.data.errors) {
          this.validationErrors = response.data.errors;
          this.showErrors = true;
        }
        return false;
      });
    },
  },
  created() {
    // console.log('created donate component');
  }
}
</script>

<style lang="scss">
.booking-payment-header {
  letter-spacing: 2px;
}

.booking-payment-label {
  position: relative;
  height: 120px;
  width: 80%;
  min-width: 80%;
  border-radius: 10px;
  border: 1px solid #ced4da;
  box-shadow: 0rem 0.5rem 0.5rem rgba(0, 0, 0, .25);
  padding: 5px;
  margin: 5px 0px 15px 0px;
  transition: 0.5s;
  -moz-transition: 0.5s;
  -webkit-transition: 0.5s;
  -o-transition: 0.5s;
  transform: scale(1);
  -webkit-transform: scale(1);
  -moz-transform: scale(1);
  -ms-transform: scale(1);
  -o-transform: scale(1);
}

.booking-payment-icon {
  width: 30px;
  height: auto;
}

.booking-info {
  border: 1px solid #ced4da !important;
  border-radius: 10px !important;
}

.property-thumbnails {
  .property-thumbnails-box {
    width: 70%;
    margin: 0 auto;
    display: block;
  }
  .thumbnails-img {
    width: 100%;
    cursor: pointer;
    height: auto;
    max-height: 200px;
    min-height: auto;
    //max-height: 200px !important;
    //min-height: 200px !important;
  }
  .carousel-inner{
    width:100%;
    border-radius: 20px;
  }
  .property-details-section {
    .col-8 {
      width: 75% !important;
    }
  }
  .property-details-section *:hover {
    cursor: pointer !important;
  }
}

</style>